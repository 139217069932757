const { useEffect } = require("react")

const useHSScript = () => {
    useEffect(() => {
    const script = document.createElement('script')
    script.src = 
    script.async = 
    script.defer = 
    script.id = 

    document.body.appendChild(script)
    return () => {
        document.body.removeChild(script)
    }
    },[]) 
  
}

export default useHSScript